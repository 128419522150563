﻿//
// Zimmer Biomet CDN
//

// Base URL
$AZURE_CDN_BASE_URL: "https://zbmymobilitydev.azureedge.net/static";

// Fonts
$AZURE_CDN_FONTS: "#{$AZURE_CDN_BASE_URL}/fonts";

// Font Awesome
$AZURE_CDN_FONTS_FONTAWESOME: "#{$AZURE_CDN_FONTS}/fontawesome6";

// NotoSans
$AZURE_CDN_FONTS_NOTOSANS: "#{$AZURE_CDN_FONTS}/NotoSans";

$max_width: 3840px;
$maxInnerPageWidth: 1200px;
$footer_height: 50px;
$standard_space: 20px;
$element_border_radius: 5px;
$area_border_radius: 10px;


// Link Colors
$secondary_link_color: rgba(0, 0, 0, 0.6);
$secondary_link_color_disabled: rgba(0, 0, 0, 0.3);
$secondary_link_color_hover: #000;

// Footer Colors
$footer_background_color: #0081C9;
$footer_link_color_hover: #fdc401;
$footer_link_color_disabled: #c9c9c9;

// Colors
$primary_color: #007dbb;
$primary_color_active: #179adc;
$primary_text: #414241;
$dark_accent_color: #414241;
$faint_text: #9aa3ac;
$dark_gray: #373737;
$gray: #e1e1e1; // rgb(225, 225, 225)
$medium_gray: #999999;
$medium_light_gray: #bbb;
$light_gray: #ededed;
$very_light_gray: #f2f4f7;
$disabled_bg_gray: #f9fafb;
$lime_green: #42b983;
$pale_yellow: #fffcaf;
$strong_yellow: #e6ce00;
$white: #fff;
$error_color: #b90425;

// Button Colors
$primary_button_color: #0081C9;
$primary_button_color_disabled: #80C0E4;
$primary_button_color_hover: #80C0E4;
$secondary_button_color: #F2F4F7;
$secondary_button_color_disabled: #F2F4F7;
$secondary_button_color_disabled_text: #B9B9B9;
$secondary_button_color_hover: #DFDFDF;
$cancel_button_text_color: #0081C9;
$cancel_button_text_color_hover: #79c4ed;
$login_button_color: $dark_accent_color;
$login_button_color_disabled: #0081a0;
$login_button_color_disabled_text: #80c0d0;
$login_button_color_hover: #004961;

// Fonts
$zbEdgeFont: NotoSans, Helvetica, Arial, sans-serif;

// Font Size
$font_size_button_text: 1rem;
$font_size_text: 1rem;
$font_size_text_large: 1.2rem;

// Font Weights
$font_weight_bold: bold;
$font_weight_400: 400;
$font_weight_600: 600;

@mixin input-style($font_size, $width) {
  font-size: $font_size;
  border: 0px;
}

@mixin secondary-button-style() {
  background-color: $light_gray;
  font-weight: $font_weight_400;
  font-size: 16px;
  line-height: 1.375;
  color: black;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  padding: 9px 40px 10px;
  border-radius: 20px;
  border: none;

  &:hover {
    background-color: $secondary_button_color_hover;
    color: black;
  }

  &:disabled {
    background-color: $secondary_button_color_disabled;
    color: $secondary_button_color_disabled_text;
  }
}

@mixin primary-link-style() {
  color: $login_button_color;
  font-weight: normal;
  font-size: .9rem;
  display: block;
  margin-bottom: 5px;

  &:hover {
    color: $login_button_color_hover;
  }

  &:disabled {
    color: $login_button_color_disabled;
  }
}

@mixin secondary-link-style() {
    color: $secondary_link_color;
    font-weight: normal;
    font-size: .9rem;
    display: block;
    margin-bottom: 5px;

    &:hover {
        color: $secondary_link_color_hover;
    }

    &:disabled {
        color: $secondary_link_color_disabled;
    }
}
